import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ClasificacionCarteraService {

    constructor(private http: HttpClient) {}

    /**
     * Consulta de acreditados
     */
    public getDatos(clave, fecha): Observable<any[]> {
        return this.http.post('api/control-cartera/reportes/clasificacioncartera/getDatos', 
            { "clave": clave, "fecha": fecha})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }

    /**
     *  Genera excel
     */
    public generaExcel(body): Observable<any[]> {
        return this.http.post('api/control-cartera/reportes/clasificacioncartera/getXlsx', 
        body, {responseType: 'blob'}).pipe(
            map(res => JSON.parse(JSON.stringify(res)) )
        );
    }

     /**
     * Fecha
     */
     public getFecha(): Observable<any[]> {
        return this.http.post('api/control-cartera/reportes/clasificacioncartera/getFecha', 
            {})
        .pipe(
        map(res => {
            return JSON.parse(JSON.stringify(res));
        })
        );
    }
}