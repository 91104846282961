import { MovExtraordinariosService } from './control-cartera/cc-operacion/mov-extraordinarios/movextraordinarios.service';
import { OperAcumuladasService } from './pld/operaciones/secciones-monitoreo/operaciones-acumuadas/operacumuladas-services';
import { NgModule } from '@angular/core';
import { PersonalService } from './personal/personal.service';
import { ImportacionidueService } from './control-cartera/cc-operacion/importacionidue/importacionidue.service';
import { PermisosService } from './permisos/permisos.service';
import { EstadoCuentaService } from '@serv/inversionistas/reportes/estadodecuenta/estadodecuenta.service';
import { MontosIdentificacionService } from './pld/administracion/parametros-sistema/montos-identificacion/montos-identificacion.services';
import { MovimientosInversionService } from './inversionistas/operaciones/movimientos-inversion/movimientosInversion.service';
import { ProyectosInversionService } from './inversionistas/operaciones/proyectos-inversion/proyectosInversion.service';
import { DetallesInversionService } from './inversionistas/operaciones/detalles-inversion/detallesInversion.service';
import { MovInversionistasService } from './inversionistas/operaciones/inversionistas/mov-inversionistas.service';
import { AmortizacionService } from '@serv/control-cartera/cc-reportes/amortizacionconcredito/amortizacionconcredito.service';
import { FinancierasService } from './inversionistas/operaciones/financieras/financieras.service';
import { CreditosFondearService } from './inversionistas/operaciones/creditos-fondear/creditos-fondear.service';
import { MigracionInvService } from '@serv/inversionistas/operaciones/migracion-inversionista/migracionInv.service';
import { PuntosVentaService } from '@serv/bancos/catalogos/puntosVenta/puntosVenta.service';
import { AcreditadosService } from './general/acreditados/acreditados.service';
import { AgrupacionCreditosAFondearService } from './inversionistas/operaciones/agrupacion-creditos-a-fondear/agrupacion-creditos-fondear.service';
import { ImportacionInGrpService } from './inversionistas/operaciones/importar-integrantes-grupo/importarIntGrp.service';
import { EntregaRendimientosService } from './inversionistas/operaciones/entrega-rendimientos/entregaRendimientos.service';
import { BitacoraService } from './general/bitacora/bitacora.service';
import { AmortizacionConSolicitudService } from './control-cartera/cc-reportes/amortizacionconcredito/amortizacionconsolicitud/amortizacionconsolicitud.service';
import { UsuariosService } from './inversionistas/administracion/seguridad/usuarios/usuarios.service';
import { EstadoDeCuentaService } from '@serv/control-cartera/cc-reportes/estadodecuenta/estadodecuenta.service';
import { EstadoCuentasService } from '@serv/ahorros/catalogos/producto/estado-cuentas.service';
import { EmpresaService } from './general/empresa/empresa.service';
import { DomicilioService } from './general/domicilio/domicilio.service';
import { EstadoDeCuentaPerService } from './control-cartera/cc-reportes/estadodecuentaperiodo/estadodecuentaperiodo.service';
import { ValoresParametro } from './general/valorParametro/valorParametro.service';
import { AmortizacionConCreditoService } from './control-cartera/cc-reportes/amortizacion/amortizacion-con-credito.service';
import { AmortizacionConSolicitudV2Service } from './control-cartera/cc-reportes/amortizacion/amortizacion-con-solicitud.service';
import { SolicitudPorEsquemaService } from './control-cartera/cc-reportes/solicitudporesquema/solicitudporesquema.service';
import { DistribuyeCriService } from './general/distribuyeCri/distribuyeCri.service';
import { EstructuraOrganizacionalService } from './general/estructura-organizacional/estructuraorganizacional.service';
import { MonedaService } from './general/moneda/moneda.service';
import { ProyectosDeInversionService } from './general/proyectosdeinversion/proyectosdeinversion.service';
import { ActividadEconomicaService } from './general/actividadeconomica/actividadeconomica.service';
import { GiroService } from './general/giro/giro.service';
import { Sectorervice } from './general/sector/sector.service';
import { UbicacionDelNegocioService } from './general/ubicaciondelnegocio/ubicaciondelnegocio.service';
import { MediosDeComunicacionService } from './general/mediosdecomunicacion/mediosdecomunicacion.service';
import { VisitasDeVerificacionService } from './general/visitas-de-verificacion/visitas-de-verificacion.service';
import { UbicacionService } from './general/ubicacion/ubicacion.service';
import { RepMovimientosInversionService } from './inversionistas/reportes/rep-movimientosinversion/movimientosinversion.service';
import { MonitoreoAgendaService } from './control-cartera/cc-operacion/monitoreo/monitoreo.service';
import { YuniusMapService } from './yunius-map/yunius-map.service';
import { RouterService } from './router.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AdminGuard } from './auth/admin-guard.service';
import { Toolbar } from './toolbar.service';
import { EsquemaColumnaService } from './control-cartera/cc-catalogos/esquemas/esquemas.service';
import { DesconciliacionRapidaService } from './control-cartera/cc-operacion/extraordinarios/movimientos-inversión/desconciliaciónRapida/desconciliacionrapida.service';
import { PrestamosPorEsquemaService } from './control-cartera/cc-reportes/prestamosporesquema/prestamosporesquemas.service';
import { MatrizDeRiesgoPorEntidadService } from './pld/operacion/matriz-de-riezgo-por-entidad/matriz-de-riezgo-por-entidad.service';
import { tipoDeProductoDeCreditoService } from './general/tipoDeProductoDeCredito/tipoDeProductoDeCredito.service';
import { productosDeCreditoService } from './general/productosDeCredito/productosDeCredito.service';
import { CatalogoMatrizDeRiesgoService } from './general/catalogoMatrizDeRiesgo/catalogoMatrizDeRiesgo.service';
import { RegistroService } from './index/registro.service';
import { AcreditadosV2Service } from './control-cartera/cc-operacion/acreditados/acreditados.service';
import { CirculoCreditoService } from './control-cartera/cc-reportes/circulocredito/circulo-credito.service';
import { CuentasAhorroService } from './ahorros/reportes/cuentasdeahorro/cuentasAhorro.service';
import { SolicitudService } from './ahorros/operacion/solicitud/solicitud.service';
import { CodigoDeVerificacionService } from './control-cartera/cc-operacion/acreditados/codigodeverificacion/codigodeverificacion.service';
import { ImportacionAfiliacionesService } from './administracion-tesoreria/at-operacion/importacion-afiliaciones/importacion-afiliaciones.service';
import { AmortizacionSinSolicitudService } from './control-cartera/cc-reportes/amortizacion/amortizacion-sin-solicitud.service';
import { ConciliacionRapidaService } from './control-cartera/cc-operacion/movimientos/informerperiodicos/conciliacionrapida/conciliacionrapida.service';
import { AutorizacionPrestamosService } from './administracion-tesoreria/at-operacion/autorizacion-prestamos-individual/autorizacionprestamos.service';
import { CuentaBancariaService } from './general/cuentabancaria/cuentabancaria.service';
import { MovimientodecomisionService } from './general/movimientodecomision/movimientodecomision.service';
import { DocuementosEntregadosService } from './general/docuementosEntregados/docuementosEntregados.service';
import { ExpedienteService } from './general/expediente/expediente.service';
import { BonificacionesService } from './general/bonificaciones/bonificaciones.service';
import { ActividadPorCreditoService } from './general/actividadporcredito/actividadporcredito.service';
import { AutorizacionEscalonadaService } from './general/autorizacionEscalonada/autorizacionEscalonada.service';
import { TraspasoFondosService } from './general/traspasofondos/traspasofondos.service';
import { RevisionProcesosService } from './general/revisionProcesos/revisionProcesos.service';
import { MovimientoTraspasoFondosService } from './general/movimientoTraspasoFondos/movimientoTraspasoFondos.service';
import { ParametrosDelSistemaService } from './gestion-cobranza/administracion/parametros-del-sistema/parametros-del-sistema.service';
import { PeriodosVencidosRangosService } from './gestion-cobranza/administracion/parametros-del-sistema/periodos-vencidos-rangos/periodos-vencidos-rangos.service';
import { RecaudoDiarioRangosService } from './gestion-cobranza/administracion/parametros-del-sistema/recaudo-diario-rangos/recaudo-diario-rangos.service';
import { SituacionDeCreditoRangosService } from './gestion-cobranza/administracion/parametros-del-sistema/situacion-de-credito/situacion-de-credito.service';
import { TipoDeActividadService } from './general/tipoDeActividad/tipoDeActividad.service';
import { CobrarComisionService } from './control-cartera/cc-operacion/movimientos/importaciones/depositosbancarios/comisiondeposito/cobrarComision.service';
import { CuentasBancariasService } from './general/cuentasbancarias/cuentasBancarias.service';
import { ProveedoresService } from './control-cartera/cc-operacion/movimientos/importaciones/depositosbancarios/proveedores/proveedores.service';
import { ProgramaService } from './general/programas/programa.service';
import { FondoService } from './general/fondos/fondos.service';
import { PagosDepositoService } from './control-cartera/cc-operacion/movimientos/importaciones/depositosbancarios/pagosdeposito/pagosDeposito.service';
import { DepositosBancariosService } from './control-cartera/cc-operacion/movimientos/importaciones/depositosbancarios/depositosBancarios.service';
import { ArchivoExcelService } from './control-cartera/cc-operacion/movimientos/importaciones/depositosbancarios/archivoexcel/archivoexcel.service';
import { MpDuplicadosService } from './control-cartera/cc-operacion/movimientos/importaciones/depositosbancarios/mpduplicados/depositosBancarios.service';
import { SolicitudDeCreditoService } from './general/solicitudDeCredito/solicitudDeCredito.service';
import { FotoImagenService } from './general/fotoImagen/fotoImagen.service';
import { EvaluacionDeRiesgoService } from './pld/reportes/evaluacionDeRiesgo.service';
import { CalendarioPagos } from './control-cartera/cc-operacion/movimientos/calendariopagos/calendariopagos.service';
import { SolicitudPrestamoIndService } from './control-cartera/cc-operacion/prestamos/individual/solicitud/solicitudprestamoind.service';
import { TipoProductoCreditoService } from './control-cartera/cc-operacion/prestamos/individual/tipo-producto-credito/tipo-producto-credito.service';
import { TipoCreditoService } from './control-cartera/cc-operacion/prestamos/individual/tipo-credito/tipo-credito.service';
import { ProductoDeCreditoService } from './control-cartera/cc-operacion/prestamos/individual/producto-credito/tipo-producto-credito.service';
import { MetodoCobroInteresService } from './control-cartera/cc-operacion/prestamos/individual/metodo-cobro-interes/metodo-cobro-interes.service';
import { FormaDistribucionService } from './control-cartera/cc-operacion/prestamos/individual/forma-distribucion/forma-distribucion.service';
import { TasaInteresService } from './control-cartera/cc-operacion/prestamos/individual/tasa-interes/tasa-interes.service';
import { EsquemaRecargoService } from './control-cartera/cc-operacion/prestamos/individual/esquema-recargo/esquema-recargo.service';
import { MovComisionGarantiaService } from './control-cartera/cc-operacion/prestamos/individual/mov-comision-garantia/mov-comision-garantia.service';
import { BeneficiarioService } from './control-cartera/cc-operacion/prestamos/individual/beneficiario/beneficiario.service';
import { TipoCirculoCreditoService } from './control-cartera/cc-operacion/prestamos/individual/tcc/tcc.service';
import { CalificacionService } from './control-cartera/cc-operacion/prestamos/individual/calificacion/calificacion.service';
import { ComentarioService } from './control-cartera/cc-operacion/prestamos/individual/comentario/comentario.service';
import { DestinoCreditoService } from './control-cartera/cc-operacion/prestamos/individual/destino-credito/destino-credito.service';
import { CalenMontosUsualmenteAltosService } from './control-cartera/cc-operacion/prestamos/individual/cmu/cmu.service';
import { CicloRealService } from './control-cartera/cc-operacion/prestamos/individual/cicloreal/cicloreal.service';
import { CicloService } from './control-cartera/cc-operacion/prestamos/individual/ciclo/ciclo.service';
import { TipoMovimientoService } from './control-cartera/cc-operacion/prestamos/individual/datcomdgpcr/datcomdgpcr.service';
import { InfoPLDService } from './control-cartera/cc-operacion/prestamos/individual/infopld/infopld.service';
import { ComunicacionTabService } from './control-cartera/cc-operacion/prestamos/individual/comunicacion/comunicacion-tab.service';
import { CuentaBancariaPrestamosService } from './control-cartera/cc-operacion/prestamos/individual/cuenta-bancaria/cuenta-banaria.service';
import { AseguradoraService } from './control-cartera/cc-operacion/prestamos/individual/aseguradora/aseguradora.service';
import { TipoContratoBuroService } from './control-cartera/cc-operacion/prestamos/individual/tcb/tcb.service';
import { TipoBuroCreditoService } from './control-cartera/cc-operacion/prestamos/individual/tcbm/tcbm.service';
import { PlazoDiaService } from './control-cartera/cc-operacion/prestamos/individual/plazodia/plazodia.service';
import { BajaPeService } from './inversionistas/usuarios/bajape/bajape.service';
import { FormaEntregaService } from './control-cartera/cc-operacion/prestamos/individual/forma-entrega/forma-entrega.service';
import { ProgramaDeMinistracionesService } from './general/programaDeMinistraciones/programaDeMinistraciones.service';
import { PEmService } from './general/pem/pem.service';
import { FolioService} from './control-cartera/cc-operacion/prestamos/individual/folio/folio.service';
import { MinistracionesService } from './control-cartera/cc-operacion/prestamos/individual/ministraciones/ministraciones.service';
import { DepService } from './control-cartera/cc-operacion/prestamos/individual/dep/dep.service';
import { AcrService } from './control-cartera/cc-operacion/prestamos/individual/acr/acr.service';
import { TipoDeImagenService } from './general/tipoDeImagen/tipoDeImagen.service';
import { CompaniasService } from './general/companias/companias.service';
import { ProductosService } from './ahorros/catalogos/productos/productos.service';
import { FondeadoresService } from './administracion-tesoreria/at-operacion/fondeadores/fondeadores.service';
import { ReportesAccesoClientesService } from './reportes-clientes/reportes-acceso-clientes.service';
import { EmpleosService } from './general/empleo/empleo.service';
import { EPAService } from './control-cartera/cc-catalogos/analisis-credito/esquemaspa.service';
import { CPAService } from './control-cartera/cc-catalogos/analisis-credito/cpa.service';
import { RPAService } from './control-cartera/cc-catalogos/analisis-credito/rpa.service';
import { SymService } from './general/SYM/sym.service';
import { TPCService } from './control-cartera/cc-catalogos/productos-credito/tipos-productos-credito.service';
import { PCRService } from './control-cartera/cc-catalogos/productos-credito/definicion-prc.service';
import { CIService } from './control-cartera/cc-catalogos/productos-credito/ciclos.service';
import { PRCCIService } from './control-cartera/cc-catalogos/productos-credito/prc-ciclos.service';
import { ProductoCreditoService } from './control-cartera/cc-catalogos/productos-credito/pcr.service';
import { GeCatService } from './control-cartera/cc-reportes/amortizacion/gecat.service';
import { AnalisisCreditoService } from './control-cartera/cc-reportes/analisiscredito/analisiscredito.service';
import { BuroCreditoService } from './control-cartera/cc-reportes/burocredito/burocredito.service';
import { ClasificacionCarteraService } from './control-cartera/cc-reportes/clasificacioncartera/clasificacioncartera.service';

@NgModule({
  providers: [
    CalendarioPagos,
    ArchivoExcelService,
    MpDuplicadosService,
    DepositosBancariosService,
    PagosDepositoService,
    ProgramaService,
    FondoService,
    ProveedoresService,
    CuentasBancariasService,
    CobrarComisionService,
    PersonalService,
    ImportacionidueService,
    PermisosService,
    EstadoCuentaService,
    MontosIdentificacionService,
    MovimientosInversionService,
    ProyectosInversionService,
    DetallesInversionService,
    MovInversionistasService,
    AmortizacionService,
    FinancierasService,
    CreditosFondearService,
    MigracionInvService,
    PuntosVentaService,
    AcreditadosService,
    AgrupacionCreditosAFondearService,
    ImportacionInGrpService,
    EntregaRendimientosService,
    BitacoraService,
    AmortizacionConSolicitudService,
    AmortizacionConSolicitudV2Service,
    AmortizacionConCreditoService,
    AmortizacionSinSolicitudService,
    UsuariosService,
    EstadoDeCuentaService,
    EmpresaService,
    DomicilioService,
    EstadoDeCuentaPerService,
    OperAcumuladasService,
    ValoresParametro,
    SolicitudPorEsquemaService,
    DistribuyeCriService,
    MovExtraordinariosService,
    EstructuraOrganizacionalService,
    MonedaService,
    ProyectosDeInversionService,
    ActividadEconomicaService,
    EstadoCuentasService,
    GiroService,
    Sectorervice,
    UbicacionDelNegocioService,
    MediosDeComunicacionService,
    VisitasDeVerificacionService,
    UbicacionService,
    RepMovimientosInversionService,
    MonitoreoAgendaService,
    YuniusMapService,
    RouterService,
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    AdminGuard,
    Toolbar,
    EsquemaColumnaService,
    DesconciliacionRapidaService,
    PrestamosPorEsquemaService,
    MatrizDeRiesgoPorEntidadService,
    tipoDeProductoDeCreditoService,
    productosDeCreditoService,
    CatalogoMatrizDeRiesgoService,
    RegistroService,
    AcreditadosV2Service,
    CirculoCreditoService,
    CuentasAhorroService,
    CatalogoMatrizDeRiesgoService,
    CuentasAhorroService,
    SolicitudService,
    EstructuraOrganizacionalService,
    CodigoDeVerificacionService,
    UbicacionService,
    ImportacionAfiliacionesService,
    ConciliacionRapidaService,
    ParametrosDelSistemaService,
    PeriodosVencidosRangosService,
    RecaudoDiarioRangosService,
    SituacionDeCreditoRangosService,
    TipoDeActividadService,
    SolicitudDeCreditoService,
    FotoImagenService,
    EvaluacionDeRiesgoService,
    SolicitudPrestamoIndService,
    TipoProductoCreditoService,
    TipoCreditoService,
    ProductoDeCreditoService,
    MetodoCobroInteresService,
    FormaDistribucionService,
    TasaInteresService,
    EsquemaRecargoService,
    MovComisionGarantiaService,
    ProgramaService,
    FondoService,
    BeneficiarioService,
    TipoContratoBuroService,
    TipoBuroCreditoService,
    TipoCirculoCreditoService,
    CalificacionService,
    ComentarioService,
    DestinoCreditoService,
    CalenMontosUsualmenteAltosService,
    CicloService,
    CicloRealService,
    TipoMovimientoService,
    InfoPLDService,
    ComunicacionTabService,
    CuentaBancariaPrestamosService,
    AseguradoraService,
    PlazoDiaService,
    BajaPeService,
    FormaEntregaService,
    ProgramaDeMinistracionesService,
    PEmService,
    FolioService,
    MinistracionesService,
    DepService,
    AcrService,
    TipoDeImagenService,
    CompaniasService,
    AutorizacionPrestamosService,
    CuentaBancariaService,
    MovimientodecomisionService,
    DocuementosEntregadosService,
    ExpedienteService,
    BonificacionesService,
    ActividadPorCreditoService,
    AutorizacionEscalonadaService,
    TraspasoFondosService,
    RevisionProcesosService,
    MovimientoTraspasoFondosService,
    ProductosService,
    FondeadoresService,
    ReportesAccesoClientesService,
    EmpleosService,
    EPAService,
    CPAService,
    RPAService,
    SymService,
    TPCService,
    PCRService,
    CIService,
    PRCCIService,
    ProductoCreditoService,
    GeCatService,
    AnalisisCreditoService,
    BuroCreditoService,
    ClasificacionCarteraService
  ]
})
export class ServiceModule { }
